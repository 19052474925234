<template>
  <div class="phone-modal default-modal">
    <div class="default-modal__header">
      <h2>Ваш телефон</h2>
      <button type="button" class="default-modal__close" @click="$emit('close')">
        <RemixIconComponent category="system" name="close-fill" />
      </button>
    </div>
    <div class="phone-modal__body">
      <InputComponent placeholder="Номер телефона" mask="+7 (###) ###-##-##" type="tel" />
    </div>
    <div class="default-modal__footer">
      <button @click="openPhoneAlertModal" class="btn btn--main btn--md">Получить код в SMS</button>
      <p>
        Нажимая на кнопку, вы соглашаетесь <br />на
        <router-link
          :to="{ name: 'static', query: { type: $store.state._types.ACT_PROCESSING_PERSONAL_DATA } }"
          target="_blank"
        >
          сбор и обработку персональных данных
        </router-link>
      </p>
    </div>
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";
import InputComponent from "components/inputs/InputComponent.vue";
import PhoneAlertModal from "./PhoneAlertModal.vue";
export default {
  name: "PhoneModal",
  methods: {
    openPhoneAlertModal() {
      this.$store.state._modals.push({
        component: PhoneAlertModal,
      });
    },
  },
  components: { InputComponent, RemixIconComponent },
};
</script>

<style lang="stylus">
.phone-modal{
  display flex
  flex-direction column
  background: var(--white);
  border-radius: 16px;
  padding 41px 32px
  width: 424px;
  &__body{
    display flex
    flex-direction column
    gap 16px
  }
  .btn{
    width: 100%;
  }
  .default-modal__footer{
    flex-direction column
    p{
      font-weight: 400;
      font-size: 0.75em;
      line-height: 120%;
      text-align: center;
      color: var(--body_muted);
    }
  }
}
</style>
