<template>
  <main class="page profile-page container-padding">
    <div class="profile-page__content">
      <ProfileAside :tabs="tabs" :active-tab="activeTab" />
      <component :is="activeTab.component" />
    </div>
    <ProfileBanner v-if="activeTab.id === 1" />
  </main>
</template>

<script>
import ProfileAside from "./components/ProfileAside.vue";
import ProfileTab from "./tabs/ProfileTab.vue";
import OrdersTab from "./tabs/OrdersTab.vue";
import MyKitsTab from "./tabs/MyKitsTab.vue";
import MyAddressesTab from "./tabs/MyAddressesTab.vue";
import MyFeedbackTab from "./tabs/MyFeedbackTab.vue";
import CompanyTab from "./tabs/CompanyTab.vue";
import ProfileHelpTab from "./tabs/ProfileHelpTab.vue";
import ProfileBanner from "./components/ProfileBanner.vue";
import MyCardsTab from "./tabs/MyCardsTab.vue";
import NotificationsTab from "./tabs/NotificationsTab.vue";

export default {
  name: "ProfilePage",
  data() {
    return {
      tabs: [
        {
          id: 1,
          title: "Профиль",
          component: ProfileTab,
          link: "profile",
        },
        {
          id: 2,
          title: "Заказы",
          component: OrdersTab,
          link: "orders",
        },
        // {
        //   id: 3,
        //   title: "Мои наборы",
        //   component: "MyKitsTab",
        // },
        {
          id: 4,
          title: "Мои адреса",
          component: MyAddressesTab,
          link: "addresses",
        },
        {
          id: 5,
          title: "Мои отзывы",
          component: "MyFeedbackTab",
          link: "reviews",
        },
        // {
        //   id: 6,
        //   title: "Компания",
        //   component: "CompanyTab",
        // },
        // {
        //   id: 7,
        //   title: "Помощь и поддержка",
        //   component: "ProfileHelpTab",
        // },
        {
          id: 8,
          title: "Мои карты",
          component: MyCardsTab,
          link: "cards",
        },
        {
          id: 9,
          title: "Мои уведомления",
          component: NotificationsTab,
          link: "notifications",
        },
      ],
    };
  },
  computed: {
    activeTab() {
      return this.tabs.find((tab) => tab.link === this.$route.params.link) || this.tabs[0];
    },
  },
  components: {
    ProfileAside,
    ProfileTab,
    OrdersTab,
    MyKitsTab,
    MyAddressesTab,
    MyFeedbackTab,
    CompanyTab,
    ProfileHelpTab,
    ProfileBanner,
    MyCardsTab,
  },
  metaInfo() {
    return {
      title: this.activeTab.title || "Профиль",
    };
  },
};
</script>

<style lang="stylus">
.profile-page {
  display flex
  flex-direction column
  gap 32px
  padding-top 32px

  &__banner {
    position relative
    border-radius 16px
    overflow hidden
    height 480px

    +below(1024px) {
      height 280px
    }
  }
  &__banner-img {
    background var(--black)
    height 100%
    width 100%

    img {
      width 100%
      height 100%
    }
  }

  &__banner-wrapper {
    display flex
    justify-content center
    align-items center
    width 100%
    position absolute
    top 0
    padding-left 450px
    height 100%
    +below(1024px) {
      padding-left: 20px;
    }
  }

  &__banner-content {
    display flex
    flex-direction column
    width 100%
    max-width 450px
    +below(1024px) {
      gap 10px
    }
  }

  &__banner-title {
    font-weight: 500;
    font-size: 48px;
    line-height 58px
    color var(--white)

    +below(1024px) {
      font-size 2em
      line-height: 30px;
    }
  }

  &__banner-description {
    font-weight: 400;
    font-size: 1.125em;
    line-height: 24px;
    color var(--white)

    +below(1024px) {
      font-size 1em
    }
  }

  &__banner-btn {
    display inline-flex
    width 173px
    margin-top 32px
    font-weight: 400;
    font-size: 14px;
    padding-left 0
    padding-right 0

    .icon {
      width 20px
      height 20px
    }
  }

  &__content {
    display flex
    gap 24px

    +below(1024px) {
      flex-direction column
    }
  }
}
</style>
