<template>
  <section>ProfileHelpTab</section>
</template>

<script>
export default {
  name: "ProfileHelpTab",
};
</script>

<style lang="stylus"></style>
