<template>
  <section class="profile-tab">
    <div v-show="false" class="profile-tab-card">
      <div class="profile-tab-card__title">
        <span>Бонусная карта</span>
      </div>
      <div class="profile-tab-card__content">
        <span class="profile-tab-card__count">100</span>
        <span class="profile-tab-card__bonus">бонусов</span>
      </div>
      <button @click="openBonusModal" class="profile-tab-card__link btn btn--white btn--sm">
        Условия программы
      </button>
    </div>
    <div class="profile-tab-info">
      <div class="profile-tab-info__content">
        <!--        <div class="profile-tab-info__img">-->
        <!--          <ImgComponent src="/static/images/avatar.png" />-->
        <!--        </div>-->
        <div class="profile-tab-info__name-block">
          <span class="profile-tab-info__name">{{ user.name }} {{ user.surname }}</span>
          <!--          <span class="profile-tab-info__age">21 год</span>-->
        </div>
        <div class="profile-tab-info__contact">
          <a href="#" class="profile-tab-info__phone">{{ user.phone }}</a>
          <a href="#" class="profile-tab-info__mail">{{ user.email }}</a>
        </div>
      </div>
      <button @click="openOptionsModal" class="btn btn--white btn--sm profile-tab-info__btn">
        <span>Настройки</span>
      </button>
    </div>
  </section>
</template>

<script>
// import ImgComponent from "components/ImgComponent.vue";
import OptionsModal from "../modals/OptionsModal.vue";
import BonusModal from "../modals/BonusModal.vue";

export default {
  name: "ProfileTab",
  // components: { ImgComponent },
  computed: {
    user() {
      return this.$store.state.auth.user || {};
    },
  },
  methods: {
    openOptionsModal() {
      this.$store.state._modals.push({
        component: OptionsModal,
        options: {},
      });
    },
    openBonusModal() {
      this.$store.state._modals.push({
        component: BonusModal,
        options: {},
      });
    },
  },
};
</script>

<style lang="stylus">
.profile-tab {
  width 100%
  display flex
  gap 24px
  +below(700px) {
    flex-direction column-reverse
  }

  &-card {
    display flex
    flex-direction column
    align-items center
    justify-content space-between
    padding 40px 0 32px 0
    width 100%
    background: linear-gradient(77.27deg, #5AB033 0%, #DDE954 100%);
    box-shadow: 0 18px 39px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
  }

  &-card__title {
    color #425B2E
    font-weight 700
    font-size 1.500em
  }

  &-card__content {
    display flex
    flex-direction column
    align-items center
    color #425B2E
  }

  &-card__count {
    font-weight 900
    font-size 6em
    line-height 96px
  }

  &-card__bonus {
    font-weight 400
    font-size 1.250em
    line-height 28px
  }

  &-card__link {
    background var(--white)
    padding 10px 22px
    border-radius 12px
    color var(--gray-800)
    font-size 0.75em
    transition var(--transition)

    &:active {
      transform scale(0.96)
    }
  }

  &-info {
    position relative
    width 100%
    max-width 312px
    background var(--gray-100)
    border-radius 16px

    +below(700px) {
      max-width: 100%;
    }
  }
  &-info__content {
    padding 20px
    display flex
    flex-direction column
    align-items center
    justify-content center
    height 100%
    gap 16px

    +below(480px) {
      align-items flex-start
    }
  }

  &-info__img {
    width 120px
    height 120px
  }

  &-info__name-block {
    display flex
    flex-direction column
    text-align center
  }

  &-info__name {
    color var(--gray-900)
    font-size 1rem
    line-height 22px
    font-weight 500
  }

  &-info__age {
    font-weight 400
    font-size 12px
    color #A5A5A5
  }

  &-info__contact {
    display flex
    flex-direction column
    align-items center

    +below(480px) {
      align-items flex-start
    }
  }

  &-info__phone {
    color var(--gray-800)
    font-weight: 400;
    font-size: 0.875em
  }

  &-info__mail {
    font-weight: 400;
    font-size: 0.875em
    line-height 20px
    color var(--blue)
  }

  &-info__btn {
    position absolute
    top 20px
    right 20px
  }

}
</style>
