<template>
  <div class="notify-item" @click="$emit('open-modal')">
    <div class="notify-item__icon">
      <figure v-if="item.icon" v-html="item.icon" class="icon" />
      <RemixIconComponent v-else category="system" name="checkbox-circle-fill" />
    </div>
    <div class="notify-item__info">
      <div class="notify-item__info-title">{{ item.title }}</div>
      <div>{{ item.subtitle }}</div>
    </div>
    <div class="notify-item__date">{{ item.send_at | formatDate }}</div>
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";

export default {
  emits: ["open-modal"],
  name: "notify",
  components: { RemixIconComponent },
  props: {
    item: Object,
  },
  data() {
    return {};
  },
};
</script>

<style lang="stylus">
.notify-item {
  cursor: pointer
  border 1px solid var(--border-color-2)
  gap 8px
  padding 8px
  border-radius 16px
  min-height: 66px;
  display: grid;
  grid-template-columns: 48px 1fr 64px;

  &__icon {
    width 48px
    height: 48px;
    border-radius 8px
    overflow hidden
    background var(--green-50)
    display flex
    justify-content center
    align-items center

		.icon {
			width 16px
			height 16px

			svg path {
				fill var(--green)
			}
		}
  }

  &__info {
    display flex
    flex-direction column
    gap 4px
    font-weight 400
    font-size 0.725rem
    line-height 14px
    justify-content center
    color var(--gray-5)

    &-title {
      font-weight 600
      font-size 1rem
      line-height 22px
    }
  }

  &__date {
    font-size 0.725rem
    line-height 14px
    justify-content center
    color var(--gray-5)
    align-self flex-end
  }
}
</style>
