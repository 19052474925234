<template>
  <section class="company-tab">
    <div class="company-tab__header">
      <div class="company-tab__title">Компания</div>
      <button @click="openAddCompanyModal" class="btn btn--outline btn--md company-tab__btn">
        <RemixIconComponent category="system" name="add-circle-line" />
        <span>Добавить компанию</span>
      </button>
    </div>
    <div class="=company-tab__body">
      <CompanyCard />
    </div>
  </section>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";
import CompanyCard from "../components/CompanyCard.vue";
import AddCompanyModal from "../modals/AddCompanyModal.vue";

export default {
  name: "CompanyTab",
  methods: {
    openAddCompanyModal() {
      this.$store.state._modals.push({
        component: AddCompanyModal,
      });
    },
  },
  components: { RemixIconComponent, CompanyCard },
};
</script>

<style lang="stylus">
.company-tab {
  display flex
  flex-direction column
  width 100%
  gap 16px

  &__header {
    display flex
    justify-content space-between
    align-items center

    +below(400px) {
      flex-direction column
      gap 20px
      align-items start
    }
  }

  &__title {
    font-weight 500
    font-size 28px
    line-height 34px
  }

  &__btn {
    border-radius 16px
    gap 4px
  }
}
</style>
