<template>
  <section class="my-feedback-tab">
    <div class="my-feedback-tab__header">Мои отзывы</div>
    <div class="my-feedback-tab__content">
      <FeedbackCard v-for="(item, i) in rows.data" :key="i" :data="item" @update="update" />
    </div>
  </section>
</template>

<script>
import FeedbackCard from "../components/FeedbackCard.vue";
import MY_REVIEWS from "@/graphql/queries/purchase/my_product_reviews.graphql";
export default {
  name: "MyFeedbackTab",

  mounted() {
    this.get();
  },
  data() {
    return {
      first: 15,
      page: 1,
      rows: {
        data: [],
        paginatorInfo: {
          total: 0,
        },
      },
    };
  },
  methods: {
    update() {
      this.get();
    },
    async get() {
      // my_product_reviews
      await this.$apollo.provider.clients.purchase
        .query({
          query: MY_REVIEWS,
          variables: {
            id: this.id,
          },
          fetchPolicy: "no-cache",
          context: {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          },
        })
        .then(({ data }) => {
          this.rows = data.my_product_reviews;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {
    token() {
      return this.$store.state.auth_token;
    },
  },
  components: {
    FeedbackCard,
  },
};
</script>

<style lang="stylus">
.my-feedback-tab {
  width 100%
  display flex
  flex-direction column
  padding-top 5px
  gap 16px

  &__header {
    font-weight 500
    font-size 28px
    line-height 34px
    height 34px
  }

  &__content {
    display flex
    flex-direction column
    gap 8px
  }
}
</style>
