<template>
  <div class="set-buy" :class="{ 'set-buy--column': column, 'set-buy--main': main }">
    <router-link :to="{ name: 'sets-item', params: { id: data.id } }" class="set-buy__image-container">
      <ImgComponent :img="data.img" :alt="data.title" />
    </router-link>
    <div class="set-buy__info-header">
      <div class="set-buy__info-header--text">
        <span class="set-buy__info-title">{{ data.title }}</span>
        <span class="set-buy__info-subtitle">
          {{ data.products_count | plural("товар", "товара", "товаров") }}
        </span>
      </div>
      <!--      <button @click="toggleLike" class="btn btn&#45;&#45;gray&#45;&#45;outline btn&#45;&#45;circle">-->
      <!--        <RemixIconComponent category="health" name="heart-3-line" />-->
      <!--      </button>-->
    </div>
    <div class="set-buy__info-footer">
      <!--      <span class="set-buy__info-footer_rating">-->
      <!--        <RemixIconComponent category="system" name="star-fill" /> {{ data.ratingCount }}</span-->
      <!--      >-->
      <ProductCountChangeComponent :data="data" isSet />
    </div>
  </div>
</template>

<script>
import ImgComponent from "components/ImgComponent.vue";
import RemixIconComponent from "components/RemixIconComponent.vue";
import ProductCountChangeComponent from "components/product/ProductCountChangeComponent.vue";
export default {
  components: { ProductCountChangeComponent, RemixIconComponent, ImgComponent },
  props: {
    column: {
      type: Boolean,
      required: false,
    },
    main: {
      type: Boolean,
      required: false,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLiked: false,
    };
  },
  methods: {
    toggleLike() {
      this.isLiked = !this.isLiked;
    },
    addToCart() {
      // Add logic for adding data to cart
    },
  },
};
</script>

<style lang="stylus">
.set-buy {
  display: grid;
  grid-template-columns 140fr 218fr
  align-items: center;
  background-color: var(--white);
  gap 16px
  padding: 24px;
  border: 1px solid var(--border-color-2);
  border-radius: 16px;

  +below(1024px) {
    grid-template-columns  1fr
    padding 10px
    gap 7px
  }

  &--column {
    display flex
    flex-direction column
    z-index 10
    .set-buy__info-header{
      order 0
    }
    .set-buy__info-footer{
      order 2
      justify-content: flex-end;
    }
    .set-buy__image-container {
      order 1

      +below(1024px) {
        width: 100%;
      }
      img{
        height: 262px;
        width: 262px;

        +below(1024px) {
          height: 200px;
          width: 100%;
        }
      }
    }
  }

  &--main {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    border: none
    padding: 0
    width: 100%;
    background: var(--white-3);
    border-radius 0

    .set-buy__image-container {
      grid-area: 1 / 1 / 2 / 3;
      order 0
      img{
        height: 499px;
        width: 100%;

        +below(840px) {
          height 320px
        }
      }
    }
    .btn--circle{
      display none
    }
    .set-buy__info-footer_rating {
      position: absolute;
      top: 24px;
      left: 24px;
    }
  }
  &__image-container {
    grid-area: 1 / 1 / 3 / 2;
    img{
      border-radius: 16px;
      height: 140px;
      width: 140px;

      +below(1024px) {
        width: 100%;
      }
    }
  }

  &__info {
    display flex
    flex-direction column
    justify-content space-between
    width: 100%;
    height: 140px;
  }
  &__info-header {
    display flex
    justify-content space-between
    width: 100%;
    &--text{
      display flex
      flex-direction column
      gap 4px
    }
  }
  &__info-title {
    font-weight: 400;
    font-size: 1.125em;
    line-height: 22px;
    text-align: left;
    color: var(--gray-900);

    +below(540px) {
      font-size: 1rem
      line-height 22px
    }
  }
  &__info-subtitle{
    text-align: start;
    font-weight: 400;
    font-size: 0.75em;
    line-height: 16px;
    color: var(--body_muted);
  }
  &__info-footer{
    display flex
    justify-content space-between
    width: 100%;
    align-items: center;
    span {
      height: fit-content;
      border-radius 4px
      font-size: 0.75em;
      line-height: 19px;
    }
    &_scores {
      background-color var(--yelow-100)
      font-weight: 400
      padding 4px
      line-height: 120%;

    }
    &_rating {
      background-color var(--gray-100)
      padding 5px 8px
      line-height: 120%;
      gap 4px
      svg {
        max-width: 15px;
        max-height: 15px;
        path {
          fill var(--yelow-500)
        }
      }

    }
  }
}
</style>
