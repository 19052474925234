<template>
  <section class="orders-tab">
    <div class="orders-tab__header">
      <div class="orders-tab__title">История заказов</div>
      <!--      <FilterTabs :tabs="tabs" @change-tab="changeTab" :active-tab="activeTab.id" />-->
    </div>
    <div class="orders-tab__list" v-if="orders.length && !loading">
      <OrderComponent
        v-for="item in orders"
        :key="item.id"
        :data="item"
        @open-order-modal="openOrderModal"
        @order-repeat="confirmOrderRepeat"
      />
    </div>
    <LoadingIndicator v-if="loading" title="Загрузка" />
    <div v-if="!loading && !orders.length">
      <span>Пока нет добавленных заказов</span>
    </div>
  </section>
</template>

<script>
import OrderComponent from "../components/OrderComponent.vue";
import FilterTabs from "../components/FilterTabs.vue";
import MY_ORDERS from "@/graphql/queries/purchase/my_orders_paginate.graphql";
import OrderModal from "../modals/OrderModal.vue";
import ORDER_REPEAT from "@/graphql/mutations/purchase/OrderRepeat.graphql";
import AlertModal from "components/modals/components/AlertModal.vue";
import { confirmOrderRepeat } from "../../../utils";
import LoadingIndicator from "components/Loadingindicator.vue";
export default {
  name: "OrdersTab",
  mounted() {
    this.get();
  },
  data() {
    return {
      loading: false,
      list: [
        // {
        //   id: 1,
        //   products: [1, 2, 3, 4],
        // },
        // {
        //   id: 2,
        //   products: [1, 2, 3],
        // },
        // {
        //   id: 3,
        //   products: [1, 2],
        // },
        // {
        //   id: 4,
        //   products: [1, 2, 3, 4],
        // },
      ],
      activeTab: {
        id: 1,
        name: "Все",
      },
      tabs: [
        {
          id: 1,
          name: "Все",
        },
        {
          id: 2,
          name: "Активные",
        },
        {
          id: 3,
          name: "Завершённые",
        },
        {
          id: 4,
          name: "Отменённые",
        },
      ],
    };
  },

  methods: {
    confirmOrderRepeat,
    openOrderModal(id) {
      this.$store.state._modals.push({
        component: OrderModal,
        options: {
          id: id,
          orderRepeat: () => this.confirmOrderRepeat(id),
        },
      });
    },
    changeTab(tab) {
      if (tab.id === this.activeTab.id) {
        return;
      }
      this.activeTab = tab;
    },
    get() {
      if (!this.loading) {
        this.loading = true;
        this.$apolloProvider.clients.purchase
          .query({
            query: MY_ORDERS,
            fetchPolicy: "no-cache",
            context: {
              headers: {
                Authorization: "Bearer " + this.token,
              },
            },
          })
          .then(({ data }) => {
            this.$store.state.auth.my_orders = data.my_orders;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
  computed: {
    token() {
      return this.$store.state.auth_token;
    },
    orders() {
      return this.$store.state.auth.my_orders;
    },
  },
  components: {
    LoadingIndicator,
    FilterTabs,
    OrderComponent,
  },
};
</script>

<style lang="stylus">
.orders-tab {
  width 100%
  display flex
  flex-direction column
  gap 17px

  &__header {
    display flex
    align-items center
    justify-content space-between

    +below(900px) {
      flex-direction column
      align-items start
      gap 20px
    }
  }

  &__title {
    font-weight 500
    font-size 1.750em
  }

  &__list {
    display flex
    flex-direction column
    gap 16px
  }
}
</style>
