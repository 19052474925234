<template>
  <section class="my-cards-tab">
    <div class="my-cards-tab__header">
      <div class="my-cards-tab__title">Мои карты</div>
      <button @click="addCard" class="btn btn--outline btn--md my-cards-tab__btn">
        <RemixIconComponent category="system" name="add-circle-line" />
        <span> Добавить карту </span>
      </button>
    </div>
    <div class="my-cards-tab__content">
      <PayCardComponent v-for="(item, i) in my_cards" :key="i" :item="item" />
      <span v-show="!(my_cards && my_cards.length)">Карты отсутствуют</span>
    </div>
  </section>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";
import CARD_CREATE from "@/graphql/mutations/purchase/ClientCardCreate.graphql";
import ClickOutside from "vue-click-outside";
import PayCardComponent from "../components/CardComponent.vue";

export default {
  name: "MyCardsTab",
  data() {
    return {
      active: null,
    };
  },
  computed: {
    my_cards() {
      return this.$store.state.auth.my_cards || [];
    },
    token() {
      return this.$store.state.auth_token;
    },
  },
  methods: {
    addCard() {
      this.$apollo.provider.clients.purchase
        .mutate({
          mutation: CARD_CREATE,
          context: {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          },
        })
        .then(({ data }) => {
          if (data.ClientCardCreate && data.ClientCardCreate.payment_link) {
            let link = document.createElement("a");
            link.href = data.ClientCardCreate.payment_link;
            link.click();
          }
        });
    },
  },
  directives: {
    ClickOutside,
  },
  components: {
    PayCardComponent,
    RemixIconComponent,
  },
};
</script>

<style lang="stylus">
.my-cards-tab {
  width 100%
  display flex
  flex-direction column
  gap 16px

  &__header {
    display flex
    justify-content space-between
    align-items center

    +below(430px) {
      flex-direction column
      gap 20px
      align-items start
      width: 100%;
    }
  }

  &__icon {
    width 36px
  }

  &__title {
    font-weight: 500;
    font-size: 1.750em;
  }

  &__content {
    display grid
    grid-template-columns repeat(2, 1fr)
    grid-gap 24px

    +below(450px) {
      grid-template-columns 1fr
    }
  }

  &__btn {
    .icon {
      width 20px
      height 20px
    }
  }

  &__card {
    position relative
    width 100%
    padding 16px
    border-radius 16px
    border 1px solid var(--border-color-2)
    display flex
    //justify-content space-between
    align-items center
    gap 12px

    &-info {
      display flex
      flex-direction column
      font-size: 0.875rem
      line-height 20px
    }

    &-title {
      font-size .875em
      line-height 140%
      color var(--body_muted)
    }
  }

  &__popper-wrapper {
    position relative
    align-self flex-start
    margin-left: auto
  }

  &__popper {
    position absolute
    padding 4px 8px
    right: 100%;
    top: 50%;
    transform: translateY(-50%);
    box-shadow 0 8px 24px rgba(149, 157, 165, 0.2)
    border-radius 8px
    z-index 10
    background var(--white)

    &:before {
      position absolute
      content ''
      right -2px
      top 50%
      transform rotate(45deg) translateY(-50%)
      width 12px
      height 12px
      background var(--white)
      pointer-events none
    }

    button {
      border-radius 8px
      font-weight: 400;
      font-size: 0.875em
      background var(--white)

      &:hover, &:active {
        background var(--gray-100)
      }
    }
  }

  &__popper-remove {
    display flex
    justify-content flex-start
    width 100%
    color var(--red)
  }

  &__popper-btn {
    height 30px
    width 30px

    &:not(&:hover) {
      background transparent
    }

    .icon {
      width 16px
      height 16px
      svg path {
        fill var(--gray-700)
      }
    }
  }
}
</style>
