<template>
  <ul class="filter-tabs">
    <li
      class="filter-tabs__item"
      v-for="tab in tabs"
      :key="tab.id"
      :class="{ 'filter-tabs__item--active': activeTab === tab.id }"
      @click="changeTab(tab)"
    >
      <span>{{ tab.name }}</span>
    </li>
  </ul>
</template>

<script>
export default {
  name: "FilterTabs",

  props: {
    activeTab: {
      type: Number,
      default: 1,
    },
    tabs: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    changeTab(tab) {
      this.$emit("change-tab", tab);
    },
  },
};
</script>

<style lang="stylus">
.filter-tabs {
  display flex
  padding 2px
  border 1px solid var(--border-color-2)
  border-radius 8px
  gap 2px

  +below(900px) {
    height: 100%;
    width: 100%;
    justify-content space-between
  }

  +below(530px) {
    flex-direction column
  }

  &__item {
    display flex
    justify-content center
    align-items center
    width 140px
    height 40px
    cursor pointer
    transition var(--transition)
    border-radius 8px
    +below(900px) {
      height: 100%;
      width: 100%;
    }

    &:not(&--active):hover {
      background var(--black-o1)
    }

    &:active {
      background var(--black-o6)
      transform scale(0.96)
      color var(--white)
    }
  }

  &__item--active {
    background var(--gray-800)
    color var(--white)
  }
}
</style>
