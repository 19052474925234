<template>
  <label class="address-component" :class="{ 'address-component--selected': isSelected }">
    <RadioComponent :checked="isSelected" @change="changeAddress(data.id)" />
    <div class="address-component__info">
      <span class="address-component__title">{{ data.title || "Без названия" }}</span>
      <span class="address-component__subtitle">{{ data.address }}</span>
    </div>
    <div v-click-outside="closePopper" class="address-component__popper-wrapper">
      <button class="btn btn--gray address-component__btn" @click="togglePopper">
        <RemixIconComponent category="system" name="more-2-line" />
      </button>
      <div v-if="active" class="address-component__popper">
        <!--        <button @click="setMainAddress(data.id)" :disabled="isSelected" class="btn btn&#45;&#45;gray btn&#45;&#45;sm">-->
        <!--          {{ isSelected ? "Выбран" : "Выбрать" }}-->
        <!--        </button>-->
        <button @click="remove(data.id)" class="btn btn--sm address-component__popper-remove">Удалить</button>
      </div>
    </div>
  </label>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";
import ClickOutside from "vue-click-outside";
import ADDRESS_DELETE from "@/graphql/mutations/purchase/DeliveryAddressDelete.graphql";
import CHANGE_ADDRESS from "@/graphql/mutations/purchase/DeliverySelectAddress.graphql";
import RadioComponent from "components/inputs/RadioComponent.vue";
import AlertModal from "components/modals/components/AlertModal.vue";

export default {
  name: "AddressComponent",
  components: { RadioComponent, RemixIconComponent },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      active: false,
      loading: false,
    };
  },
  computed: {
    token() {
      return this.$store.state.auth_token;
    },
    selectedAddress() {
      return this.$store.state.auth.client_delivery_info.selected_address?.id;
    },
    isSelected() {
      return this.selectedAddress === this.data.id;
    },
  },
  methods: {
    changeAddress(id) {
      if (this.selectedAddress !== id) {
        this.$store.state._modals.push({
          component: AlertModal,
          options: {
            title: "Вы уверены?",
            message:
              "После смены адреса, некоторые товары в корзине могут быть удалены, вы точно хотите изменить адрес?",
            closeBtnTitle: "Нет",
            closeBtnClass: "btn--gray",
            isConfirm: true,
            confirmBtnTitle: "Да",
            callback: this.setMainAddress,
            args: [id],
          },
        });
      }
    },
    setMainAddress(id) {
      if (!this.loading) {
        this.loading = true;
        this.$apollo.provider.clients.purchase
          .mutate({
            mutation: CHANGE_ADDRESS,
            variables: {
              id,
            },
            context: {
              headers: {
                Authorization: "Bearer " + this.token,
              },
            },
          })
          .then(({ data }) => {
            if (data.DeliverySelectAddress) {
              // this.$store.state._modals.push({
              //   component: AlertModal,
              //   options: {
              //     title: "Успешно",
              //     message: "",
              //   },
              // });
              this.$store.dispatch("GET_ADDRESSES", {
                apollo: this.$apollo.provider.clients,
              });
              this.$store.dispatch("GET_DELIVERY_INFO", {
                apollo: this.$apollo.provider.clients,
              });
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    remove(id) {
      if (!this.loading) {
        this.loading = true;
        this.$apollo.provider.clients.purchase
          .mutate({
            mutation: ADDRESS_DELETE,
            variables: {
              id,
            },
            context: {
              headers: {
                Authorization: "Bearer " + this.token,
              },
            },
          })
          .then(({ data }) => {
            if (data.DeliveryAddressDelete) {
              this.$store.dispatch("GET_ADDRESSES", {
                apollo: this.$apollo.provider.clients,
              });
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    togglePopper() {
      this.active = !this.active;
    },
    closePopper() {
      if (this.active === false) {
        return;
      }
      this.active = false;
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style lang="stylus">
.address-component {
  display flex
  justify-content space-between
  gap: 15px
  position relative
  width 100%
  padding 16px
  border-radius 16px
  border 1px solid var(--border-color-2)
  cursor: pointer;

  & .radio {
    min-width 17px
  }

  &--selected {
    border-color var(--main)
  }

  &__info {
    display flex
    flex-direction column
    gap: 4px
  }

  &__title {
    font-weight: 500;
  }

  &__subtitle {
    display flex
    align-items center
    font-weight: 400;
    font-size: 0.875em
    line-height 14px
    color var(--body_muted)
  }

  &__popper-wrapper {
    position relative
    align-self flex-start
    min-width 30px
  }

  &__popper {
    position absolute
    padding 4px 8px
    right: 100%;
    top: 50%;
    transform: translateY(-50%);
    box-shadow 0 8px 24px rgba(149, 157, 165, 0.2)
    border-radius 8px
    z-index 10
    background var(--white)

    &:before {
      position absolute
      content ''
      right -2px
      top 50%
      transform rotate(45deg) translateY(-50%)
      width 12px
      height 12px
      background var(--white)
      pointer-events none
    }

    button {
      border-radius 8px
      font-weight: 400;
      font-size: 0.875em
      background var(--white)

      &:hover, &:active {
        background var(--gray-100)
      }
    }
  }

  &__popper-remove {
    display flex
    justify-content flex-start
    width 100%
    color var(--red)
  }

  &__btn {
    height 30px
    width 30px

    &:not(&:hover) {
      background transparent
    }

    .icon {
      width 16px
      height 16px
      svg path {
        fill var(--gray-700)
      }
    }
  }
}
</style>
