<template>
  <div class="notify-modal default-modal">
    <div class="notify-modal__img">
      <ImgComponent :img="item.img" />
      <div class="notify-modal__close">
        <button @click="$store.state._modals = []" class="btn btn--gray btn--md">
          <RemixIconComponent category="system" name="close-fill" />
        </button>
      </div>
    </div>
    <div class="notify-modal__info">
      <div class="notify-modal__info-title">{{ item.title }}</div>
      <div class="notify-modal__info-message">
        <EditorJSComponent :text="item.message" />
      </div>
      <div v-if="item.promo" class="notify-modal__info-promo">
        <div class="notify-modal__info-promo-title">{{ item.promo?.promo }}</div>
        <button
          v-if="!isCopied"
          class="btn btn--md btn--gray notify-modal__btn"
          @click="copy(item.promo?.promo)"
        >
          Скопировать
        </button>
        <button v-else class="btn btn--md btn--gray notify-modal__btn">
          <RemixIconComponent category="system" name="checkbox-circle-fill" />
          <span> Скопировано </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";
import ImgComponent from "components/ImgComponent.vue";
import IconComponent from "components/IconComponent.vue";
import EditorJSComponent from "components/EditorJSComponent.vue";
export default {
  name: "NotifyModal",
  props: {
    item: Object,
  },

  data() {
    return {
      isCopied: false,
    };
  },

  methods: {
    async copy(promo) {
      this.isCopied = true;
      try {
        await navigator.clipboard.writeText(promo);
      } catch (err) {
        console.error("Ошибка при копировании текста: ", err);
      }
      setTimeout(() => {
        this.isCopied = false;
      }, 3000);
    },
  },
  components: { EditorJSComponent, IconComponent, ImgComponent, RemixIconComponent },
};
</script>

<style lang="stylus">
.notify-modal{
  padding 0
  display flex
  flex-direction column
  background: var(--white);
  border-radius: 16px;
  width: 648px;
  overflow hidden


  &__img {
    width 100%
    position relative
    height 232px
  }

  &__close {
    position absolute
    top 32px
    right 32px

    .btn {
      width 48px
      height 48px
    }
  }

  &__info {
    padding 32px
    display flex
    flex-direction column
    gap 16px

    &-title {
      text-align center
      font-weight 500
      font-size 1.75rem
      line-height 40px
			+below(480px) {
				font-size 1.25rem
				line-height normal
			}
    }

    &-message {
      text-align center
      font-weight 400
      font-size 1rem
      line-height 22px
    }

    &-promo {
      align-self center
      display flex
      gap 16px
      align-items  center

      &-title {
        font-size 1.5rem
        font-weight 500
      }
    }
  }

  &__btn {
    align-self center
    display flex
    align-items center
    gap 4px
  }
}
</style>
