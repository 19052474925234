<template>
  <section class="my-addresses-tab">
    <div class="my-addresses-tab__header">
      <div class="my-addresses-tab__title">Мои адреса</div>
      <button @click="openDeliveryModal" class="btn btn--outline btn--md my-addresses-tab__btn">
        <RemixIconComponent category="system" name="add-circle-line" />
        <span> Добавить адрес </span>
      </button>
    </div>
    <div class="my-addresses-tab__content">
      <AddressComponent v-for="(item, i) in addresses" :key="i" :data="item" />
      <span v-if="!(addresses && addresses.length)">Адреса отсутствуют</span>
    </div>
  </section>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";
import AddressComponent from "../components/AddressComponent.vue";
import DeliveryModal from "components/modals/components/DeliveryModal.vue";

export default {
  name: "MyAddressesTab",
  computed: {
    addresses() {
      return this.$store.state.auth.my_addresses;
    },
    token() {
      return this.$store.state.auth_token;
    },
  },
  methods: {
    openDeliveryModal() {
      this.$store.state._modals.push({
        component: DeliveryModal,
        options: {
          isProfile: true,
        },
      });
    },
  },
  components: {
    RemixIconComponent,
    AddressComponent,
  },
};
</script>

<style lang="stylus">
.my-addresses-tab {
  width 100%
  display flex
  flex-direction column
  gap 16px

  &__header {
    display flex
    justify-content space-between
    align-items center

    +below(430px) {
      flex-direction column
      gap 20px
      align-items start
      width: 100%;
    }
  }

  &__title {
    font-weight: 500;
    font-size: 1.750em;
  }

  &__content {
    display grid
    grid-template-columns repeat(2, 1fr)
    grid-gap 24px

    +below(450px) {
      grid-template-columns 1fr
    }
  }

  &__btn {
    .icon {
      width 20px
      height 20px
    }
  }
}
</style>
