<template>
  <section class="my-noticed">
    <div class="my-noticed__header">
      <div class="my-noticed__title">Мои уведомления</div>
    </div>
    <div class="my-noticed__content">
      <div class="" v-for="(item, i) in items" :key="i">
        <NotifyComponent :item="item" @open-modal="openModal(item)" />
      </div>
    </div>

    <PaginationComponent :page="page" :list="{ data: items, paginatorInfo }" @change="handlePaginate" />
  </section>
</template>

<script>
import NotifyComponent from "../components/NotifyComponent.vue";
import NOTICES from "@/graphql/queries/lk/notices_paginate.graphql";
import SetCard from "components/SetBuyCardComponent.vue";
import RemixIconComponent from "components/RemixIconComponent.vue";
import NotifyModal from "../modals/NotifyModal.vue";
import PaginationComponent from "components/Pagination.vue";
export default {
  name: "MyKitsTab",
  mounted() {
    this.get();
  },

  data() {
    return {
      first: 10,
      page: 1,
      items: [],
      paginatorInfo: {},
    };
  },

  methods: {
    openModal(item) {
      this.$store.state._modals.push({
        component: NotifyModal,
        options: {
          item,
        },
      });
    },

    handlePaginate($event) {
      console.log($event);
      this.page = $event;
      this.get();
    },

    get() {
      if (!this.loading) {
        this.loading = true;
        this.$apolloProvider.clients.lk
          .query({
            query: NOTICES,
            variables: {
              first: this.first,
              page: this.page,
            },
            fetchPolicy: "no-cache",
            context: {
              headers: {
                Authorization: "Bearer " + this.token,
              },
            },
          })
          .then(({ data }) => {
            this.items = data.notices_paginate.data;
            this.paginatorInfo = data.notices_paginate.paginatorInfo;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
  components: {
    PaginationComponent,
    NotifyComponent,
    RemixIconComponent,
    SetCard,
  },
};
</script>

<style lang="stylus">
.my-noticed {
  width 100%
  display flex
  flex-direction column
  gap 16px

  &__header {
    display flex
    justify-content space-between
    align-items center
  }

  &__title {
    font-weight 500
    font-size 1.750em
  }

  &__btn {
    font-size 0.875em

    .icon {
      width 20px
      height 20px
    }
  }

  &__content {
    display flex
    flex-direction column
    gap 16px
  }

  //&__content {
  //  display grid
  //  grid-template-columns repeat(3, 1fr)
  //  grid-gap 24px
  //
  //  +below(1300px) {
  //    grid-template-columns  1fr 1fr
  //  }
  //
  //  +below(540px) {
  //    grid-template-columns 1fr
  //  }
  //}


}
</style>
