<template>
  <div class="card-component">
    <IconComponent :name="setCardIcon(item)" class="card-component__icon" />
    <div class="card-component__info">
      <span>{{ setCardIcon(item) }}</span>
      <span class="card-component__title">**{{ item.masked_pan.slice(-4) }}</span>
    </div>
    <div v-click-outside="closePopper" class="card-component__popper-wrapper">
      <button class="btn btn--gray card-component__popper-btn" @click="togglePopper">
        <RemixIconComponent category="system" name="more-2-line" />
      </button>
      <div v-if="active" class="card-component__popper">
        <!--        <button class="btn btn&#45;&#45;gray btn&#45;&#45;sm">Редактировать</button>-->
        <button @click="remove(item.id)" class="btn btn--sm card-component__popper-remove">Удалить</button>
      </div>
    </div>
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";
import IconComponent from "components/IconComponent.vue";
import CARD_DELETE from "@/graphql/mutations/purchase/ClientCardDelete.graphql";
import ClickOutside from "vue-click-outside";

export default {
  name: "PayCardComponent",
  components: { IconComponent, RemixIconComponent },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      active: null,
    };
  },
  methods: {
    setCardIcon(card) {
      switch (card.masked_pan.charAt(0)) {
        case "2":
          return "MIR";
        case "4":
          return "VISA";
        case "5":
          return "Mastercard";
      }
    },
    togglePopper() {
      this.active = !this.active;
    },
    closePopper() {
      if (this.active === false) {
        return;
      }
      this.active = false;
    },
    remove(id) {
      if (!this.loading) {
        this.loading = true;
        this.$apollo.provider.clients.purchase
          .mutate({
            mutation: CARD_DELETE,
            variables: {
              id,
            },
            context: {
              headers: {
                Authorization: "Bearer " + this.token,
              },
            },
          })
          .then(({ data }) => {
            if (data.ClientCardDelete) {
              this.$store.dispatch("GET_CARDS", {
                apollo: this.$apollo.provider.clients,
              });
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
  computed: {
    token() {
      return this.$store.state.auth_token;
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style lang="stylus">
.card-component {
  position relative
  width 100%
  padding 16px
  border-radius 16px
  border 1px solid var(--border-color-2)
  display flex
  //justify-content space-between
  align-items center
  gap 12px

  &__icon {
    width 36px
  }

  &__info {
    display flex
    flex-direction column
    font-size: 0.875rem
    line-height 20px
  }

  &__title {
    font-size .875em
    line-height 140%
    color var(--body_muted)
  }

  &__popper-wrapper {
    position relative
    align-self flex-start
    margin-left: auto
  }

  &__popper {
    position absolute
    padding 4px 8px
    right: 100%;
    top: 50%;
    transform: translateY(-50%);
    box-shadow 0 8px 24px rgba(149, 157, 165, 0.2)
    border-radius 8px
    z-index 10
    background var(--white)

    &:before {
      position absolute
      content ''
      right -2px
      top 50%
      transform rotate(45deg) translateY(-50%)
      width 12px
      height 12px
      background var(--white)
      pointer-events none
    }

    button {
      border-radius 8px
      font-weight: 400;
      font-size: 0.875em
      background var(--white)

      &:hover, &:active {
        background var(--gray-100)
      }
    }
  }

  &__popper-remove {
    display flex
    justify-content flex-start
    width 100%
    color var(--red)
  }

  &__popper-btn {
    height 30px
    width 30px

    &:not(&:hover) {
      background transparent
    }

    .icon {
      width 16px
      height 16px
      svg path {
        fill var(--gray-700)
      }
    }
  }
}
</style>
