<template>
  <div class="feedback-card">
    <div class="feedback-card__header">
      <div class="feedback-card__info">
        <ImgComponent class="feedback-card__img" :img="data?.product?.img" />
        <span class="feedback__title">{{ data?.product?.title }}</span>
      </div>
      <div class="feedback-card__actions">
        <button class="btn btn--red feedback-card__btn-delete" @click="removeReview">
          <Loadingindicator v-if="removing" title="" />
          <RemixIconComponent v-else category="system" name="delete-bin-line" />
        </button>
        <button
          v-if="data.comment"
          class="btn btn--gray feedback-card__btn-down"
          :class="{ 'feedback-card__btn-down--active': active }"
          @click="toggleActive"
        >
          <RemixIconComponent category="system" name="arrow-down-s-line" />
        </button>
      </div>
    </div>
    <ul v-if="active" class="feedback-card__content">
      <li>
        <div class="feedback-card__comment-header">
          <div class="feedback-card__comment-info">
            <div class="feedback-card__comment-rate">
              <RemixIconComponent category="system" name="star-fill" />
              <span>{{ data.main_mark }}</span>
            </div>
            <span class="feedback-card__comment-title">{{ data?.client?.name }}</span>
          </div>
          <span class="feedback-card__comment-date">
            {{ humanDate(data.created_at) }}
          </span>
        </div>
        <div class="feedback-card__comment-description">
          {{ data.comment }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import ImgComponent from "components/ImgComponent.vue";
import RemixIconComponent from "components/RemixIconComponent.vue";
import dayjs from "dayjs";
import ProductReviewModal from "components/modals/components/ProductReviewModal.vue";
import REMOVE_REVIEW from "@/graphql/mutations/purchase/ReviewDelete.graphql";
import Loadingindicator from "components/Loadingindicator.vue";
export default {
  name: "FeedBackCard",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      active: false,
      removing: false,
    };
  },
  methods: {
    removeReview() {
      this.removing = true;
      this.$apollo.provider.clients.purchase
        .mutate({
          mutation: REMOVE_REVIEW,
          variables: {
            id: this.data.id,
          },
          context: {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          },
        })
        .then(() => {})
        .finally(() => {
          this.removing = false;
          this.$emit("update");
        });
    },
    update() {
      this.$emit("update");
    },
    openProductReviewModal() {
      this.$store.state._modals.push({
        component: ProductReviewModal,
        options: {
          id: this.data.id,
          callback: this.update,
        },
      });
    },
    humanDate(date) {
      require("dayjs/locale/ru");
      dayjs.locale("ru");
      return dayjs(date).format("DD MMMM YYYY");
    },
    toggleActive() {
      this.active = !this.active;
    },
  },
  computed: {
    token() {
      return this.$store.state.auth_token;
    },
  },
  components: { RemixIconComponent, ImgComponent, Loadingindicator },
};
</script>

<style lang="stylus">
.feedback-card {
  width 100%
  padding 16px
  border-radius 16px
  border 1px solid var(--border-color-2)

  &__header {
    display flex
    justify-content space-between
    align-items center
    height 64px
  }

  &__info {
    display flex
    align-items center
    gap 8px
  }

  &__img {
    width 60px
    height 49px
    object-fit: contain;
  }

  &__actions {
    display flex
    align-items center
    gap 10px
  }

  &__btn-communication {
    border-radius 12px
  }



  &__btn-down {
    border-radius 12px
    width 36px
    height 36px

    .icon {
      transition var(--transition)
      width 16px
      height 16px
    }
  }

  &__btn-delete {
    border-radius 12px
    width 36px
    height 36px

    .loading-indicator {
      justify-content center
    }

    .icon {
      transition var(--transition)
      width 16px
      height 16px
    }
  }

  &__btn-down--active {

    .icon {
      transform rotate(180deg)
    }
  }

  &__title {
    font-weight: 500;
    font-size: 1em;
    line-height 19px
    color var(--gray-900)
  }

  &__content {
    display flex
    flex-direction column
    gap 16px

    li {
      display flex
      flex-direction column
      border-top 1px solid var(--border-color-2)
      padding-top: 16px;
      gap 8px
    }
  }

  &__comment-header {
    display flex
    justify-content space-between
    align-items center
  }

  &__comment-info {
    display flex
    gap 8px
  }

  &__comment-rate {
    display flex
    align-items center
    gap 4px
    padding 4px 8px
    height 24px
    border-radius 4px
    background var(--gray-100)

    span {
      padding-top: 1px;
      font-weight 600
      font-size 0.75em
      line-height 16px
    }

    .icon {
      width 16px
      height 16px

      svg path {
        fill var(--yelow-500)
      }
    }
  }

  &__comment-title {
    font-weight: 500;
    font-size: 1em;
  }

  &__comment-date {
    font-weight: 400;
    font-size: 0.75em;
    color var(--body_muted)
  }

  &__comment-description {
    font-weight: 400;
    font-size: 0.875em;
    color var(--gray-900)
  }
}
</style>
