<template>
  <div class="phone-modal default-modal">
    <div class="default-modal__header">
      <h2>Добавить компанию</h2>
      <button type="button" class="default-modal__close" @click="$emit('close')">
        <RemixIconComponent category="system" name="close-fill" />
      </button>
    </div>
    <div class="phone-modal__body">
      <h6>Укажите ИНН, Название компании мы подгрузим сами и вы сможете его отредактировать</h6>
      <InputComponent placeholder="Введите ИНН" title="ИНН" />
      <InputComponent placeholder="Название" title="Название" />
    </div>
    <div class="default-modal__footer">
      <button class="btn btn--main btn--md">Добавить компанию</button>
      <p>
        Проверьте данные - после сохранения отредактировать их<br />
        будет нельзя
      </p>
    </div>
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";
import InputComponent from "components/inputs/InputComponent.vue";
export default {
  name: "AddCompanyModal",
  methods: {
    // openModal() {
    //   this.$store.state._modals.push({
    //     component: ,
    //   });
    // },
  },
  components: { InputComponent, RemixIconComponent },
};
</script>

<style lang="stylus">
.phone-modal{
  display flex
  flex-direction column
  background: var(--white);
  border-radius: 16px;
  padding 41px 32px
  width: 424px;
  &__body{
    display flex
    flex-direction column
    gap 16px
  }
  .btn{
    width: 100%;
  }
  .default-modal__footer{
    flex-direction column
    p{
      font-weight: 400;
      font-size: 0.75em;
      line-height: 120%;
      text-align: center;
      color: var(--body_muted);
    }
  }
}
</style>
