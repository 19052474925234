<template>
  <aside class="profile-aside">
    <ul class="profile-aside__list">
      <li class="profile-aside__item" v-for="(tab, i) in tabs" :key="i" @click="changeTab(tab)">
        <router-link
          :to="{ name: 'profile', params: { link: tab.link } }"
          :class="{ 'profile-aside__link--active': JSON.stringify(tab) === JSON.stringify(activeTab) }"
          class="profile-aside__link"
        >
          <span>
            {{ tab.title }}
          </span>
          <RemixIconComponent category="system" name="arrow-right-s-line" />
        </router-link>
      </li>
    </ul>
  </aside>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";
export default {
  name: "ProfileAside",
  props: {
    activeTab: Object,
    tabs: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    changeTab(tab) {
      this.$emit("change-tab", tab);
    },
  },

  components: { RemixIconComponent },
};
</script>

<style lang="stylus">
.profile-aside {
  padding 16px
  border 1px solid var(--border-color-2)
  border-radius 16px
  width 100%
  max-width 312px
  height fit-content
  position: sticky;
  top: 100px;

  +below(1024px) {
    max-width: 100%;
    position static
  }

  &__link {
    display flex
    justify-content space-between
    padding 12px
    font-weight 400
    font-size 0.875em
    line-height 20px
    color var(--gray-800)
    cursor pointer
    border-radius 16px
    transition var(--transition)

    &:hover {
      background var(--gray-100)
    }

    &:active {
      transform scale(0.96)
    }

    &--active {
      background var(--gray-100)
    }

    .icon {
      width 20px
      height 20px
    }
  }
}
</style>
