<template>
  <div class="bonus-modal default-modal">
    <div class="default-modal__header">
      <h2>Бонусная программа</h2>
      <button type="button" class="default-modal__close" @click="$emit('close')">
        <RemixIconComponent category="system" name="close-fill" />
      </button>
    </div>
    <div class="bonus-modal__body">
      <h6>Бонусная программа</h6>
      <h2>Ваши условия</h2>
      <div class="progress-bar">
        <div class="progress-bar-line">
          <div class="progress-bar-fill" :style="{ width: progress + '%' }"></div>
        </div>
        <div class="progress-bar-circles">
          <div v-for="i in numCircles" :key="i" :class="{ filled: i <= progressStep }"></div>
        </div>
      </div>
    </div>
    <div class="default-modal__footer">
      <button class="btn btn--main btn--md">Посмотреть все условия</button>
    </div>
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";
export default {
  name: "BonusModal",
  props: {
    progress: {
      type: Number,
      default: 50,
    },
    numCircles: {
      type: Number,
      default: 5,
    },
  },
  computed: {
    progressStep() {
      return Math.round((this.progress / 100) * this.numCircles);
    },
  },
  components: { RemixIconComponent },
};
</script>

<style lang="stylus">
.bonus-modal{
  display flex
  flex-direction column
  background: var(--white);
  border-radius: 16px;
  padding 41px 32px
  width: 424px;
  &__body{
    display flex
    flex-direction column
    gap 16px
  }
  .btn{
    width: 100%;
  }

  .progress-bar {
    display: flex;
    align-items: center;
  }

  .progress-bar-line {
    position: relative;
    height: 2px;
    width: 100%;
    background-color: var(--gray);
  }

  .progress-bar-fill {
    position: absolute;
    height: 100%;
    background-color: var(--main);
  }
  .progress-bar-circles {
    display: flex;
    align-items: center;
    margin-left: -334px;
    gap 30px
  }

  .progress-bar-circles div {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--gray);
    margin-right: 5px;
  }

  .progress-bar-circles div.filled {
    background-color: #27ae60;
  }
}
</style>
