<template>
  <div class="order-product">
    <div class="order-product__wrapper-info">
      <div class="order-product__img">
        <ImgComponent :img="data.product.img" />
      </div>
      <div class="order-product__name">
        <span class="order-product__title">{{ data.product.title }}</span>
        <span class="order-product__description">
          {{ pricePerQuant }}
        </span>
      </div>
    </div>
    <div class="order-product__wrapper-interactions">
      <div class="order-product__action">
        <span class="order-product__price">
          <span class="order-product__price-old" v-if="data.product.price.price_without_discount">
            {{ data.product.price.price_without_discount | formatPrice }}
          </span>
          <span
            class="order-product__price-actual"
            :class="{ 'order-product__price-actual--discount': data.product.price.price_without_discount }"
          >
            {{ data.price | formatPrice }}
          </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import ImgComponent from "components/ImgComponent.vue";

export default {
  name: "OrderProductCardComponent",
  components: { ImgComponent },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    this.count = this.data.quantity;
  },
  watch: {
    "data.quantity"(newValue) {
      this.count = newValue;
    },
  },
  computed: {
    pricePerQuant() {
      if (this.data.product.unit_type_id === this.types.UNIT_GRAM && this.data.product.price.price_per_kg) {
        return `${this.data.product.price.price_per_kg} ₽ за 1 кг - ${this.data.quantity} шт.`;
      }
      return `${this.data.product.price.value} ₽ за 1 шт - ${this.data.quantity} шт.`;
    },
    types() {
      return this.$store.state._types;
    },
  },
};
</script>

<style lang="stylus">
.order-product {
  width 100%
  padding: 8px 16px
  display flex
  gap 16px
  align-items center
  border: 1px solid var(--border-color-2, #E3E3E3);
  border-radius: 16px;
  height 76px
  +below(920px) {
    flex-direction column
    height auto
    align-items flex-start
    gap 16px
  }

  &__name {
    display flex
    flex-direction column
    gap 4px
    flex-grow 1
  }

  &__title {
    font-weight: 500;
    font-size: 1rem
    line-height: 19px;
    color var(--gray-900)
    max-width 400px
    +below(920px) {
      max-width none
    }
    +below(720px) {
      font-size: 0.875rem
    }
  }

  &__description {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--gray-5)
  }

  &__img {
    width 64px
    height 64px
    background: var(--white)
    display flex
    border-radius: 16px;
    flex-shrink 0

    & img {
      object-fit contain
      border-radius 5px
    }
  }

  &__action {
    display flex
    gap 16px
    align-items center
    +below(920px) {
      width 100%
    }
  }

  &__price {
    display flex
    flex-direction column
    justify-content flex-end
    text-align right
    font-weight: 500;
    font-size: 1.125rem
    line-height: 24px;
  }

  &__price-old {
    font-weight: 500;
    font-size: 0.625rem
    line-height: 12px;
    text-decoration-line: line-through;
    color: var(--gray-400)
  }

  &__price-actual {
    font-weight: 500;
    font-size: 1.125rem
    line-height: 24px;

    &--discount {
      color: var(--danger);
    }
  }

  &__wrapper-info {
    display flex
    gap 8px
    align-items center
  }

  &__wrapper-interactions {
    display flex
    gap 8px
    align-items center
    width 50%
    justify-content flex-end
    margin-left auto
    +below(920px) {
      justify-content flex-start
      width 100%
    }
  }
}
</style>
