<template>
  <div class="add-mail-modal default-modal">
    <div class="default-modal__header">
      <h2>Ваш email</h2>
      <button type="button" class="default-modal__close" @click="$emit('close')">
        <RemixIconComponent category="system" name="close-fill" />
      </button>
    </div>
    <div class="add-mail-modal__body">
      <h6>
        Мы отправим на него ссылку для <br />
        подтверждения доступа к почте
      </h6>
      <InputComponent v-model="form.email.value" :errors="form.email.errors" placeholder="Email" />
    </div>
    <div class="default-modal__footer">
      <button @click="submit" class="btn btn--main btn--md">
        <span>Продолжить</span>
        <IconComponent name="loading" v-if="loading" />
      </button>
    </div>
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";
import InputComponent from "components/inputs/InputComponent.vue";
import ChangeMailModal from "./ChangeMailModal.vue";
import AlertModal from "components/modals/components/AlertModal.vue";
import IconComponent from "components/IconComponent.vue";
import USER_PROFILE_EDIT from "@/graphql/mutations/lk/UserProfileEdit.graphql";
import { parseGqlErrors, resetErrors } from "@/utils";

export default {
  name: "AddMailModal",
  props: {
    callback: Function,
  },
  data() {
    return {
      loading: false,
      form: {
        email: {
          value: null,
          errors: [],
        },
      },
    };
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    token() {
      return this.$store.state.auth_token;
    },
  },
  methods: {
    resetErrors,
    parseGqlErrors,
    openChangeMailModal() {
      // this.$store.state._modals.push({
      //   component: ChangeMailModal,
      // });
    },
    submit() {
      if (!this.loading) {
        this.loading = true;
        this.resetErrors();
        this.$apollo.provider.clients.lk
          .mutate({
            mutation: USER_PROFILE_EDIT,
            variables: {
              name: this.user.name || undefined,
              email: this.form.email.value || undefined,
            },
            context: {
              headers: {
                Authorization: "Bearer " + this.token,
              },
            },
          })
          .then(({ data }) => {
            this.loading = false;
            if (data.UserProfileEdit) {
              this.$store.state._modals = [];
              this.$store.state._modals.push({
                component: AlertModal,
                options: {
                  title: "Успешно",
                  message: data.UserProfileEdit.message,
                },
              });
              this.$store.dispatch("USER", {
                apollo: this.$apollo.provider.clients,
              });
            }
          })
          .catch(({ graphQLErrors }) => {
            this.loading = false;
            this.parseGqlErrors(graphQLErrors);
          });
      }
    },
  },
  components: { IconComponent, InputComponent, RemixIconComponent },
};
</script>

<style lang="stylus">
.add-mail-modal{
  display flex
  flex-direction column
  background: var(--white);
  border-radius: 16px;
  padding 41px 32px
  width: 424px;
  &__body{
    display flex
    flex-direction column
    gap 16px
  }
  .btn{
    width: 100%;
  }
}
</style>
