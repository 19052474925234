<template>
  <div class="default-modal review-modal">
    <div class="review-modal__header">
      <div class="review-modal__header-article">
        <span>Оценить заказ</span>
      </div>
      <button class="btn btn--gray" type="button" @click="$emit('close')">
        <RemixIconComponent category="system" name="close-fill" />
      </button>
    </div>
    <div class="review-modal__body">
      <StarsRatingComponent
        v-model="form.main_mark.value"
        :errors="form.main_mark.errors"
        name="main"
        title="Оценка заказа"
      />
      <StarsRatingComponent
        v-model="form.delivery_mark.value"
        :errors="form.delivery_mark.errors"
        name="delivery"
        title="Оценка сборки"
      />
      <StarsRatingComponent
        v-model="form.assembly_mark.value"
        :errors="form.assembly_mark.errors"
        name="assembly"
        title="Оценка доставки"
      />
      <TextareaComponent
        v-model="form.comment.value"
        :errors="form.comment.errors"
        placeholder="Комментарий"
      />
    </div>
    <div class="review-modal__footer">
      <button v-if="saving" class="btn btn--gray btn--md">
        <LoadingIndicator title="" />
      </button>
      <button v-else class="btn btn--main btn--md" @click="submit">Готово</button>
    </div>
  </div>
</template>

<script>
import TextareaComponent from "components/TextareaComponent.vue";
import StarsRatingComponent from "components/inputs/StarsRatingComponent.vue";
import RemixIconComponent from "components/RemixIconComponent.vue";
import LoadingIndicator from "components/Loadingindicator.vue";
import AlertModal from "components/modals/components/AlertModal.vue";
import ORDER_REVIEW_CREATE from "@/graphql/mutations/purchase/OrderReviewCreate.graphql";

export default {
  name: "ReviewModal",
  emits: ["close"],
  props: {
    id: Number,
    callback: Function,
  },
  mounted() {},

  data() {
    return {
      saving: false,
      form: {
        main_mark: {
          value: null,
          errors: [],
        },
        delivery_mark: {
          value: null,
          errors: [],
        },
        assembly_mark: {
          value: null,
          errors: [],
        },
        comment: {
          value: null,
          errors: [],
        },
      },
    };
  },

  methods: {
    collectData() {
      const variables = {
        order_id: this.id,
      };

      Object.keys(this.form).forEach((key) => {
        variables[key] = this.form[key].value ?? undefined;
      });

      return variables;
    },
    resetErrors() {
      Object.keys(this.form).forEach((key) => {
        this.form[key].errors = [];
      });
    },
    submit() {
      this.resetErrors();
      this.saving = true;
      this.$apollo.provider.clients.purchase
        .mutate({
          mutation: ORDER_REVIEW_CREATE,
          variables: this.collectData(),
          context: {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          },
        })
        .then(() => {
          this.$emit("closeAll");
          this.$store.state._modals.push({
            component: AlertModal,
            options: {
              title: "Отзыв успешно отправлен!",
              message: "Спасибо за ваш отзыв! Мы ценим ваше мнение и будем стараться становиться еще лучше..",
            },
          });
        })
        .catch(({ graphQLErrors }) => {
          graphQLErrors.forEach((err) => {
            if (err.extensions.category === "validation") {
              Object.keys(err.extensions.validation).forEach((key) => {
                switch (key) {
                  case "main_mark":
                    this.form[key].errors = [err.extensions.validation[key][0]];
                    break;
                  case "delivery_mark":
                    this.form[key].errors = [err.extensions.validation[key][0]];
                    break;
                  case "assembly_mark":
                    this.form[key].errors = [err.extensions.validation[key][0]];
                    break;
                  default:
                    this.$store.state._modals.push({
                      component: AlertModal,
                      options: {
                        title: "Вы уже оставили отзыв!",
                        message: "Мы ценим ваше мнение и будем стараться становиться еще лучше.",
                      },
                    });
                    break;
                }
              });
            }
          });
        })
        .finally(() => {
          if (this.callback) {
            this.callback();
          }
          this.saving = false;
        });
    },
  },
  computed: {
    token() {
      return this.$store.state.auth_token;
    },
  },
  components: {
    TextareaComponent,
    StarsRatingComponent,
    RemixIconComponent,
    LoadingIndicator,
  },
};
</script>

<style lang="stylus">
.review-modal {
  width 100%
  max-width 424px;
  padding 32px
  display flex
  flex-direction column
  gap 16px

  &__header {
    display flex
    justify-content space-between
    align-items center
    font-size: 1.500rem;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
  }

  &__body {
    display flex
    flex-direction column
    align-items center
    gap 16px

    .textarea {
      width: 100%;
    }
  }

  &__footer {
    width 100%

    .btn {
      width 100%
    }
  }
}
</style>
