<template>
  <div class="phone-alert-modal default-modal">
    <div class="phone-alert-modal__body">
      <div class="phone-alert-modal__check">
        <RemixIconComponent category="system" name="check-line" />
      </div>
      <h2>Номер изменён</h2>
    </div>

    <div class="default-modal__footer">
      <button @click="$store.state._modals = []" class="btn btn--gray btn--md">Закрыть</button>
    </div>
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";
export default {
  name: "PhoneAlertModal",
  components: { RemixIconComponent },
};
</script>

<style lang="stylus">
.phone-alert-modal{
  display flex
  flex-direction column
  background: var(--white);
  border-radius: 16px;
  padding 41px 32px
  width: 424px;
  .btn{
    width: 100%;
  }
  &__body{
    display flex
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
   }
  &__check{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 56px;
    background: var(--green-50);
    border-radius: 999px;

    svg{
      max-height: 25px;
      max-width: 25px
      path{
        fill var(--green)
      }
    }
  }
}
</style>
