<template>
  <div class="company-card">
    <div class="company-card__title">ИП Кайтамазов Марк Исмаилович</div>
    <button class="btn btn--gray company-card__btn">
      <RemixIconComponent category="system" name="arrow-right-s-line" />
    </button>
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";

export default {
  name: "CompanyCard",
  components: { RemixIconComponent },
};
</script>

<style lang="stylus">
.company-card {
  display flex
  justify-content space-between
  align-items center
  width 100%
  border-radius 16px
  padding 16px
  border 1px solid var(--border-color-2)

  &__title {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
  }

  &__btn {
    width 36px
    height 36px
    border-radius 12px

    .icon {
      width 16px
      height 16px
    }
  }
}
</style>
