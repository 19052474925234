<template>
  <div class="options-modal default-modal">
    <div class="default-modal__header">
      <h2>Настройки</h2>
      <button type="button" class="default-modal__close" @click="$emit('close')">
        <RemixIconComponent category="system" name="close-fill" />
      </button>
    </div>
    <h6>Личные данные</h6>
    <!--    <div class="options-modal__image options-modal__block-border">-->
    <!--      <div class="options-modal__image-avatar">-->
    <!--        <RemixIconComponent category="user" name="user-line" />-->
    <!--      </div>-->
    <!--      <span>Нажмите для загрузки изображения или перетащите изображение сюда</span>-->
    <!--    </div>-->
    <div class="options-modal__form options-modal__block-border">
      <InputComponent v-model="form.name.value" title="Имя" placeholder="Имя" />
      <InputComponent v-model="form.surname.value" title="Фамилия" placeholder="Фамилия" />
      <div class="options-modal__items">
        <span class="options-modal__items-title">Пол</span>
        <label v-for="(item, i) in genders" :key="i" class="options-modal__item">
          <RadioComponent
            :checked="JSON.stringify(form.gender.value) === JSON.stringify(item)"
            @change="handleRadioSelect(item)"
          >
            <span class="options-modal__item-title">{{ item.title }}</span>
          </RadioComponent>
        </label>
      </div>
      <InputComponent
        v-model="form.birthday.value"
        type="date"
        title="Дата рождения"
        placeholder="Дата рождения"
      />
    </div>
    <div class="options-modal__info options-modal__block-border">
      <h6>Безопасность и вход</h6>
      <div class="options-modal__info_phone">
        <span>Телефон</span>
        <div class="options-modal__info_phone--right">
          <div>
            <h6 v-if="user.phone">{{ user.phone | vMask("+# ### ###-##-##") }}</h6>
            <h6 v-else>Не указан</h6>
            <!--            <button @click="openPhoneModal" class="btn">-->
            <!--              <RemixIconComponent category="design" name="pencil-line" />-->
            <!--            </button>-->
          </div>
          <!--          <p>подтвержден</p>-->
        </div>
      </div>
      <div class="options-modal__info_mail">
        <span>Email</span>
        <h6 @click="openAddMailModal">
          <template v-if="user.email">
            {{ user.email }}
            <button class="btn" title="Изменить email">
              <RemixIconComponent category="design" name="pencil-line" />
            </button>
          </template>
          <template v-else>Добавить email</template>
        </h6>
      </div>
      <!--      <div class="options-modal__info_socials">-->
      <!--        <span>Вход через соц. сети</span>-->
      <!--        <div class="options-modal__info_socials-buttons">-->
      <!--          <a class="btn"> <IconComponent name="google" /> </a>-->
      <!--          <a class="btn"> <IconComponent name="google" /> </a>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
    <div class="default-modal__footer">
      <button @click="logout({ store: $store, router: $router })" class="btn btn--red btn--md">Выйти</button>
      <button @click="submit" class="btn btn--main btn--md options-modal__save">
        <span>Сохранить</span>
        <IconComponent name="loading" v-if="loading" />
      </button>
    </div>
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";
import InputComponent from "components/inputs/InputComponent.vue";
import IconComponent from "components/IconComponent.vue";
import AddMailModal from "./AddMailModal.vue";
import PhoneModal from "./PhoneModal.vue";
import AlertModal from "components/modals/components/AlertModal.vue";
import { logout } from "@/utils";
import USER_PROFILE_EDIT from "@/graphql/mutations/lk/UserProfileEdit.graphql";
import RadioComponent from "components/inputs/RadioComponent.vue";
import dayjs from "dayjs";

export default {
  name: "OptionsModal",
  data() {
    return {
      loading: false,
      form: {
        name: {
          value: null,
          errors: [],
        },
        surname: {
          value: null,
          errors: [],
        },
        birthday: {
          value: null,
          errors: [],
        },
        gender: {
          value: null,
          errors: [],
        },
      },
      genders: [
        {
          id: 1,
          title: "Муж.",
        },
        {
          id: 2,
          title: "Жен.",
        },
      ],
    };
  },
  mounted() {
    this.form.name.value = this.user.name;
    this.form.surname.value = this.user.surname;
    this.form.birthday.value = this.user.birthday
      ? dayjs(this.user.birthday).format("YYYY-MM-DD")
      : undefined;
    this.form.gender.value = this.genders.find((item) => item.id === this.user.gender_type_id);
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    token() {
      return this.$store.state.auth_token;
    },
  },
  methods: {
    logout,
    openAddMailModal() {
      this.$store.state._modals.push({
        component: AddMailModal,
      });
    },
    handleRadioSelect(item) {
      this.form.gender.value = item;
    },
    openPhoneModal() {
      this.$store.state._modals.push({
        component: PhoneModal,
      });
    },
    submit() {
      if (!this.loading) {
        this.loading = true;
        this.$apollo.provider.clients.lk
          .mutate({
            mutation: USER_PROFILE_EDIT,
            variables: {
              name: this.form.name.value || null,
              surname: this.form.surname.value || null,
              email: this.user.email || undefined,
              birthday: this.form.birthday.value
                ? dayjs(this.form.birthday.value).format("YYYY-MM-DD HH:mm:ss")
                : undefined,
              gender_type_id: this.form.gender.value ? this.form.gender.value.id : undefined,
            },
            context: {
              headers: {
                Authorization: "Bearer " + this.token,
              },
            },
          })
          .then(({ data }) => {
            if (data.UserProfileEdit) {
              this.$store.state._modals.push({
                component: AlertModal,
                options: {
                  title: "Успешно",
                  message: data.UserProfileEdit.message,
                },
              });
              this.$store.dispatch("USER", {
                apollo: this.$apollo.provider.clients,
              });
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
  components: { RadioComponent, IconComponent, InputComponent, RemixIconComponent },
};
</script>

<style lang="stylus">
.options-modal{
  display flex
  flex-direction column
  background-color var(--white)
  padding 41px 32px
  width 100%
  max-width: 648px;

  &__image{
    display flex
    align-items center
    gap 24px
    &-avatar{
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--gray-100);
      border: 2px dashed var(--border-color-2);
      border-radius: 999px;
      width: 80px;
      height: 80px;
      transition var(--transition)
      svg{
        max-height 21px
        max-width 16px
        path{
          fill var(--border-color-2)
          transition var(--transition)

        }
      }
      &:hover{
        border: 2px dashed var(--green-dark);
        svg{
          path{
            fill var(--green-dark)
          }
        }
      }
    }
    span{
      font-weight: 400;
      font-size: 0.875em;
      line-height: 140%;
      color: var(--body_muted);
      max-width: 342px;
    }
  }

  &__form{
    display grid
    grid-template-columns repeat(2, 1fr)
    grid-gap 24px
    row-gap: 16px;

    +below(628px) {
      grid-template-columns repeat(1, 1fr)
    }
  }
  &__info{
    display flex
    flex-direction column
    gap 16px
    &_phone{
      display flex
      //gap 238px
      justify-content space-between

      +below(428px) {
        flex-direction column
        gap 8px
      }

      &--right{
        display flex
        flex-direction column
        gap 8px
        div{
          display flex
          gap 12px
        }
      }

      p{
        font-weight: 400;
        font-size: 0.75em;
        line-height: 120%;
        color: var(--green);
        opacity: 0.8;
      }
    }
    &_mail{
      display flex
      //gap 261px
      justify-content space-between

      +below(428px) {
        flex-direction column
        gap 8px
      }

      h6{
        display flex
        align-items center
        gap: 8px
        text-decoration-line: underline;
        color: var(--blue);
        cursor pointer
      }
    }
    &_socials{
      display flex
      //gap 155px
      justify-content space-between
      &-buttons{
        display flex
        gap 16px
        align-items center
        .icon{
          width: 32px;
          height: 32px;
        }
      }
    }
    span{
      font-weight: 400;
      font-size: 0.875em;
      line-height: 140%;
      color: var(--gray-900);
      opacity: 0.8;
    }
  }

  &__block-border{
    border-bottom: 1px solid var(--border-color-2);
    padding 16px 0
  }

  &__save {
    margin-left auto
  }

  &__items {
    width 100%
    display flex
    gap 0 32px
    align-items: center;
    flex-wrap wrap

    &-title {
      width 100%
    }
  }

  &__item {
    padding 16px 0
    border-radius 16px
    display flex
    cursor pointer
    align-items: center;
  }

  &__item-text {
    display flex
    flex-direction column
    margin-left 8px
  }

  &__item-title {
    font-size .875em
    line-height 140%
    color var(--gray-900)
  }

}
</style>
