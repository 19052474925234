<template>
  <article v-show="false" class="profile-page__banner">
    <div class="profile-page__banner-img">
      <ImgComponent src="/static/images/welcome-bg.png" />
    </div>
    <div class="profile-page__banner-wrapper">
      <div class="profile-page__banner-content">
        <span class="profile-page__banner-title"> Всё в твоих руках </span>
        <span class="profile-page__banner-description">
          Больше 4000 товаров сети супермаркетов «Зелёное Яблоко» доступен тебе на сайте и в мобильном
          приложение с бесплатной доставкой на дом!
        </span>
        <router-link class="btn btn--white btn--md profile-page__banner-btn" to="">
          <span>Перейти в каталог</span>
          <RemixIconComponent category="system" name="arrow-right-line" />
        </router-link>
      </div>
    </div>
  </article>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";
import ImgComponent from "components/ImgComponent.vue";
export default {
  name: "ProfileBanner",

  components: {
    RemixIconComponent,
    ImgComponent,
  },
};
</script>
