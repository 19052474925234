<template>
  <div class="change-mail-modal default-modal">
    <div class="default-modal__header">
      <h2>Изменение e-mail</h2>
      <button type="button" class="default-modal__close" @click="$emit('close')">
        <RemixIconComponent category="system" name="close-fill" />
      </button>
    </div>
    <div class="change-mail-modal__body">
      <h6>Если этот e-mail не занят, ссылка на изменение отправлена на <a>sample@mail.com</a></h6>
    </div>

    <div class="default-modal__footer">
      <button @click="$store.state._modals = []" class="btn btn--main btn--md">Хорошо</button>
    </div>
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";
export default {
  name: "ChangeMailModal",
  components: { RemixIconComponent },
};
</script>

<style lang="stylus">
.change-mail-modal{
  display flex
  flex-direction column
  background: var(--white);
  border-radius: 16px;
  padding 41px 32px
  width: 424px;
  .btn{
    width: 100%;
  }
  a{
    color var(--blue)
    text-decoration underline
  }
}
</style>
