<template>
  <section class="my-kits-tab">
    <div class="my-kits-tab__header">
      <div class="my-kits-tab__title">Мои наборы</div>
      <button class="btn btn--gray my-kits-tab__btn">
        <RemixIconComponent category="system" name="add-circle-line" />
        <span> Создать набор </span>
      </button>
    </div>
    <div class="my-kits-tab__content">
      <SetCard v-for="(item, i) in items" :key="i" :data="item" column />
    </div>
  </section>
</template>

<script>
import SetCard from "components/SetBuyCardComponent.vue";
import RemixIconComponent from "components/RemixIconComponent.vue";
export default {
  name: "MyKitsTab",

  data() {
    return {
      items: [
        {
          id: 1,
          image: "/static/images/zaremka.png",
          title: "Вкусный ужин",
          subtitle: "12 товаров",
          ratingCount: "4,8",
        },
        {
          id: 2,
          image: "/static/images/zaremka.png",
          title: "Вкусный ужин",
          subtitle: "12 товаров",
          ratingCount: "4,8",
        },
        {
          id: 3,
          image: "/static/images/zaremka.png",
          title: "Вкусный ужин",
          subtitle: "12 товаров",
          ratingCount: "4,8",
        },
        {
          id: 4,
          image: "/static/images/zaremka.png",
          title: "Вкусный ужин",
          subtitle: "12 товаров",
          ratingCount: "4,8",
        },
        {
          id: 5,
          image: "/static/images/zaremka.png",
          title: "Вкусный ужин",
          subtitle: "12 товаров",
          ratingCount: "4,8",
        },
        {
          id: 6,
          image: "/static/images/zaremka.png",
          title: "Вкусный ужин",
          subtitle: "12 товаров",
          ratingCount: "4,8",
        },
        {
          id: 7,
          image: "/static/images/zaremka.png",
          title: "Вкусный ужин",
          subtitle: "12 товаров",
          ratingCount: "4,8",
        },
        {
          id: 8,
          image: "/static/images/zaremka.png",
          title: "Вкусный ужин",
          subtitle: "12 товаров",
          ratingCount: "4,8",
        },
        {
          id: 9,
          image: "/static/images/zaremka.png",
          title: "Вкусный ужин",
          subtitle: "12 товаров",
          ratingCount: "4,8",
        },
      ],
    };
  },

  components: {
    RemixIconComponent,
    SetCard,
  },
};
</script>

<style lang="stylus">
.my-kits-tab {
  width 100%
  display flex
  flex-direction column
  gap 16px

  &__header {
    display flex
    justify-content space-between
    align-items center
  }

  &__title {
    font-weight 500
    font-size 1.750em
  }

  &__btn {
    font-size 0.875em

    .icon {
      width 20px
      height 20px
    }
  }

  &__content {
    display grid
    grid-template-columns repeat(3, 1fr)
    grid-gap 24px

    +below(1300px) {
      grid-template-columns  1fr 1fr
    }

    +below(540px) {
      grid-template-columns 1fr
    }
  }


}
</style>
